.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: gray;
  margin-left: 8px;
  padding: 4px;
  border: 1px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 12px;
}

.tooltip-text {
  visibility: hidden;
  width: 125px;
  height: 75px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
