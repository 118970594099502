.terms-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .terms-container h1 {
    text-align: center;
    color: #333;
  }
  
  .update-date {
    text-align: center;
    color: #666;
    margin-bottom: 10px; /* Reduced from 20px to 10px */
  }
  
  .section {
    margin-top: 3rem !important; /* Reduced vertical space between sections */
    margin-bottom: 10px !important; /* Reduced vertical space between sections */
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: justify; /* Justify text within each section */
    z-index: 5;
  }
  
  .section h2 {
    color: #333;
    margin-top: 10px; /* Reduced from 20px to 10px */
    margin-bottom: 5px; /* Reduced for less space after headings */
  }
  .legal-terms {
    /* ... other styles ... */
    text-align: justify;
  }
  .section p,
  .section ol,
  .section ul,
  .section address { /* Apply to all text elements within a section */
    line-height: 1.6;
    color: #444;
    margin-bottom: 8px; /* Adjusted for consistency */
    text-align: justify; /* Justify text */
  }
  
  .section a {
    color: #0066cc;
    text-decoration: none; /* Optional: removes the underline from links */
  }
  
  .section ol,
  .section ul {
    padding-left: 20px; /* Ensures indentation for lists */
  }
  