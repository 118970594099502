.range-control-header{
    display: flex;
    justify-content: space-between;
    margin-top: 110px;
    align-items: center;
    padding: 1.5rem;
}

.range-controls{
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 10px;
}